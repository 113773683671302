import React from "react"
import "./text-component.scss"
import { GatsbyImage } from "gatsby-plugin-image"



const TextComponent = ({ Title, Description, Button, BackgroundColor }) => {


    //console.log(BackgroundColor)

    const handleGtagReservationComponent = (pressedBtn) => {
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', `${pressedBtn}`);

        }
    }

    return (
        <>

            <div className="text-comp-container" style={{ backgroundColor: `${BackgroundColor}` }}>

                <div className="information-content">
                    <div className="information-layout">
                        <h2 className="title">{Title}</h2>
                        <b></b>
                        {Description ?
                            <p>{Description}</p>
                            : ""
                        }
                        <div className="buttons-box">
                            {Button?.map((item, index) => {
                                return (
                                    <div className="button-container" key={index} onClick={() => handleGtagReservationComponent(item.CTALabel)}>
                                        <a href={item.CTAUrl} className="cta">{item.CTALabel}</a>
                                    </div>
                                );
                            })}

                        </div>

                    </div>
                    {/* <div className="background-color"></div> */}

                </div>

            </div>

        </>
    )
}

export default TextComponent