import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../layouts"
import 'uikit/dist/css/uikit.css'
import "../styles/secondary-page.scss"
import GetImgUrl from "../utils/customFunctions"


import useSiteMetadata from "../utils/useSiteMetadata"
import ContactComponent from "../components/contact-component/contact-component"
import SecondaryLayout from "../layouts/secondary-layout"

import Header from "../components/header/header"
import Paragraph from "../components/paragraph/paragraph"
import TextImageComponent from "../components/text-image-component/text-image-component"
import ImageList from "../components/image-list/image-list"
import TextComponent from "../components/text-component/text-component"
import InfoCards from "../components/info-cards/info-cards"


// const Header = loadable(() => import("../components/header/header"))
// const Paragraph = loadable(() => import("../components/paragraph/paragraph"))



export const query = graphql`
  query SecondaryPagesQuery($id: Int) 
  {
    strapiSecondaryPages(strapiId: { eq: $id }){
      Title
      Slug
      SEOTitle
      SEODescription
      PageContent
    }
  }
`;



// markup
const SecondaryPage = ({ pageContext, data }) => {



    return (
        //returns the standard layout
        <SecondaryLayout
            SEOTitle={data.strapiSecondaryPages.SEOTitle || data.strapiSecondaryPages.Title}
            SEODescription={data.strapiSecondaryPages.SEODescription}>

            {data.strapiSecondaryPages.PageContent.map((item, index) => {


                switch (item.strapi_component) {
                    case "page-components.header":
                        return (

                            <Header key={index}
                                Title={item.Title}
                                Subtitle={item.Subtitle}
                                FirstCTATitle={item.FirstCTATitle}
                                FirstCTAURL={item.FirstCTAUrl}
                                SecondCTATitle={item.SecondCTATitle}
                                SecondCTAURL={item.SecondCTAUrl}
                                ThirdCTATitle={item.ThirdCTATitle}
                                ThirdCTAURL={item.ThirdCTAUrl}
                                Background={GetImgUrl(item.Background.localFile___NODE, pageContext.images)}
                                Icon={item.Icon !== null ? GetImgUrl(item.Icon.localFile___NODE, pageContext.images) : null}
                                HeaderHeight={item.HeaderHeight}
                                FooterInformation={item.FooterInformation !== null ? item.FooterInformation : null}
                            ></Header>
                        )

                    case "page-components.paragraph":
                        return (

                            <Paragraph
                                key={index}
                                Title={item.Title ? item.Title : ""}
                                Text={item.Text}
                                TopPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                BottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                            ></Paragraph>


                        )

                    case "page-components.text-component":
                        return (
                            <>

                                <TextComponent
                                    Title={item.Title}
                                    Description={item.Description}
                                    Button={item.Button}
                                    BackgroundColor={item.BackgroundColor} />
                            </>
                        )

                    case "page-components.text-image-component":
                        return (
                            <>

                                <TextImageComponent
                                    Image={item.Image !== null ? GetImgUrl(item.Image.localFile___NODE, pageContext.images) : null}
                                    ImagePosition={item.ImagePosition}
                                    Title={item.Title}
                                    Description={item.Description}
                                    Button={item.Button} />
                            </>
                        )

                    case "page-components.images":
                        var ListImg = []
                        item.Images.map((imgItem, index) => {
                            ListImg.push(GetImgUrl(imgItem.Image.localFile___NODE, pageContext.images))
                        })
                        return (

                            <ImageList key={index}
                                images={ListImg}
                            ></ImageList>

                        )

                    case "page-components.contact-component":
                        return (

                            <ContactComponent
                                Title={item.Title}
                                Address={item.Address}
                                HTML={item.HTML} />

                        )

                    case "page-components.info-cards":
                        return (

                            <InfoCards
                                content={item.SecondaryCards} />

                        )

                    default:
                        return ("")
                }

            })}


        </SecondaryLayout>
    )
}

export default SecondaryPage