import React from "react"
import "./info-cards.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import CustomMarkdown from "../../utils/customMarkdown"
import HtmlEditor from "../html-editor/html-editor"
import { FaFacebookF } from "react-icons/fa6";
import { BsInstagram } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import { PiLinkedinLogoBold } from "react-icons/pi";
// import { SiDeliveroo } from "react-icons/si";


const InfoCards = ({ content }) => {

    return (
        <>
            <div className="cards-main uk-child-width-expand@s uk-text-center" data-uk-grid>

                {
                    content.map((item, index) => {
                        return (
                            <div className="card-content uk-card uk-card-default uk-card-body">
                                <h2 className="card-title">{item.Title}</h2>
                                {item.Description ?
                                    <div className="card-description">
                                        <CustomMarkdown>{item.Description}</CustomMarkdown>
                                    </div>
                                    : ""
                                }
                                {item.HTML ?
                                    <div style={{ width: "100%" }}>
                                        <HtmlEditor htmlContent={item.HTML} fullWidth={true} />
                                    </div>
                                    : ""
                                }
                                {item.Button !== null ?
                                    <div className={item.Socials == true ? "icons-box" : "buttons-box"}>
                                        {
                                            item.Button.map((itemBtn, indexBtn) => {
                                                return (
                                                    <div className="cta-container" key={indexBtn}>
                                                        <a href={itemBtn.CTAUrl} className={item.Socials == true ? "icons" : "btn"} aria-label="socials" target="_blank">
                                                            {itemBtn.CTALabel == "Facebook" ? <FaFacebookF /> :
                                                                itemBtn.CTALabel == "Instagram" ? <BsInstagram /> :
                                                                    itemBtn.CTALabel == "Twitter" ? <FaXTwitter /> :
                                                                        itemBtn.CTALabel == "Linkedin" ? <PiLinkedinLogoBold /> :
                                                                            itemBtn.CTALabel}</a>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    : ""
                                }
                            </div>
                        )
                    })
                }

            </div>
        </>
    )
}

export default InfoCards