import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import "./image-list.scss";
import { FaSearchPlus } from 'react-icons/fa'


const ImageList = ({ images }) => {


    const [openModal, setOpenModal] = useState(false);

    const [currentIndex, setCurrentIndex] = useState(0);

    const clickImage = (index) => {
        setOpenModal(true);
        setCurrentIndex(index);
    };

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains("modal-overlay")) {
            setOpenModal(false);
        }
    };

    // if (openModal) {
    //     document.body.setAttribute('style', `position: fixed; top: 0; left: 0; right: 0;`)
    // } else {
    //     document.body.setAttribute('style', ``)
    // }

    return (
        <>
            <div className="images-container padding-top padding-bottom lateral-padding">
                <div className="img-content uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-text-center" uk-grid="masonry: true">
                    {images && images.map((item, index) => (
                        <div key={index} className="image-overlay-container" onClick={() => clickImage(index)}>
                            <GatsbyImage image={item.gatsbyImageData} alt={""} className="image-hovering" />
                            <div className="overlay">
                                <span className="icon-search"><FaSearchPlus /></span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {openModal && (
                <div className="show-modal modal-overlay" onClick={handleOutsideClick}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <span className="close" onClick={() => setOpenModal(false)}>&times;</span>
                        </div>
                        <div className="modal-body">
                            <Carousel
                                selectedItem={currentIndex}
                                onChange={(index) => setCurrentIndex(index)}
                                showArrows={true}
                                showStatus={false}
                                showIndicators={false}
                                infiniteLoop={true}
                            >
                                {images && images.map((item, index) => (
                                    <div key={index} className="uk-panel">
                                        <GatsbyImage image={item.gatsbyImageData} alt={""} className="gallery-img" />
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default ImageList;